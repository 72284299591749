import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter15 from '../common/src/assets/image/charity/specter/kapitola_15_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Pätnásta kapitola: Komunistické korene terorizmu | Ako duch komunizmu vládne nášmu svetu"
           description="Pätnásta kapitola: Komunistické korene terorizmu"
           image={imageChapter15}/>
        <h1>Pätnásta kapitola: Komunistické korene terorizmu</h1>
        <h2>1. Terorizmus a komunistická revolúcia</h2>
        <p>Od teroristického útoku z 11. septembra 2001 je západná verejnosť oboznámená s globálnym teroristickým hnutím a&nbsp;jeho predstaviteľmi. Už menej sa však vie o blízkom vzťahu medzi terorizmom a komunizmom.</p>
        <p>Komunistická ideológia je zakorenená v nenávisti a boji. Všetky aspekty „starej spoločnosti“, vrátane zákona a&nbsp;morálky, považuje za pozostatky represívnej vládnucej triedy, ktorá má byť zvrhnutá akýmikoľvek prostriedkami. A tak komunistické hnutie použilo terorizmus ako dôležitý nástroj vo svojom úsilí o získanie moci a&nbsp;na šírenie svojej ideológie do celého sveta. Pojmy „terorizmus“ a „terorista“ sa po prvýkrát objavili v roku 1795 v súvislosti s vládou teroru počas francúzskej revolúcie, ktorá položila základy komunistického hnutia (pozri Druhú kapitolu).<a href="#_edn1" id="_ednref1">[1]</a></p>
        <p>Vladimír Lenin pomocou terorizmu zabezpečil víťazstvo komunistov v Rusku. Marxistický teoretik Karl Kautsky, ktorý v roku 1919 vydal knihu <em>Terorizmus a komunizmus</em>, podal ucelený prehľad o tom, čo sa stane pod diktatúrou proletariátu, ktorú chcel Lenin založiť. Pri skúmaní násilia francúzskej revolúcie dospel Kautsky k záveru, že Leninovi boľševici zdedili teroristický charakter jakobínov.<a href="#_edn2" id="_ednref2">[2]</a></p>
        <p>Felix Dzeržinskij, riaditeľ Leninovej tajnej polície Čeka, v roku 1918 vyhlásil: „Podporujeme organizovanie teroru – to by sa malo otvorene priznať.“<a href="#_edn3" id="_ednref3">[3]</a> &nbsp;Čeka (tajná boľševická polícia) bola aktívna počas ruskej občianskej vojny, keď boľševici bojovali s&nbsp;antikomunistickou Bielou armádou i so súperiacimi socialistickými frakciami. Dopúšťala sa únosov, mučenia, atentátov a okamžitých popráv „triednych nepriateľov“ v širokom meradle, čo bolo súčasťou Leninových represií, ktoré sa stali známe ako „červený teror“.</p>
        <p>Čeka usilovala o to, aby do radov nepriateľov boľševikov zasiala čo najväčší strach a spôsobila im maximálnu možnú bolesť. Podľa výpovede očitého svedka uvedenej v knihe <em>Červený teror v Rusku</em>, ktorú napísal ruský historik Sergej Melgunov v exile v roku 1924, boli mnohé obete Čeky vybrané, pretože vlastnili majetok alebo patrili medzi šľachtu. V noci ich vyvádzali z domovov, prinútili ich vyzliecť sa, a potom ich zastrelili. Telá týchto zavraždených, vrátane žien, detí, dôchodcov a kňazov, často javili známky sadistického týrania – zohavenie, upálenie, stiahnutie z kože, znásilnenie, sťatie hlavy, alebo dokonca aj desivejších činov.</p>
        <p>Keď sa Čeka chválila tým, ako ľudí masakrovala, bol podľa Melgunova „počet zverejnených mien obetí len zlomkom skutočnosti“.<a href="#_edn4" id="_ednref4">[4]</a> Má sa za to, že samotná Čeka bola počas červeného teroru zodpovedná za vraždu desiatok tisíc, alebo dokonca stoviek tisíc ľudí.</p>
        <p>Sovietsky komunistický režim považoval Dzeržinského za revolučného hrdinu. Čeka bola mnohokrát premenovaná, než sa nakoniec stala Výborom pre štátnu bezpečnosť (KGB), agentom výboru sa však vždy neformálne hovorilo „čekisti“. Po smrti Dzeržinského v roku 1926, bolo po ňom pomenované námestie pred Lubjankou – hlavným sídlom KGB, a nieslo jeho meno až do roku 1990. V 40. rokoch bola potom na tomto námestí vztýčená Dzeržinského socha, ktorá tu stála do roku 1991.<a href="#_edn5" id="_ednref5">[5]</a></p>
        <p>Po ustanovení sovietskeho režimu nasledovali komunistické revolučné hnutia rovnaký vzorec červeného teroru po celom svete. Ako je uvedené v predchádzajúcich kapitolách tejto knihy, marxisticko-leninistické režimy bez výnimky používajú desivú brutalitu, keď sa zmocňujú vlády a udržujú si moc. Násilie a vražda sú však len jednou časťou komunistickej teroristickej agendy. Ešte deštruktívnejší je spôsob, akým komunizmus využíva politický a&nbsp;náboženský &nbsp;zápal, aby ľuďom vštepil kultúru komunistickej strany. Takto zasieva semienka podvodu, nenávisti a násilia, ktoré sa potom odovzdávajú z generácie na generáciu.</p>
        <p>V dnešnom svete sa terorizmus prejavuje v troch hlavných formách, a to ako štátny terorizmus v komunistických režimoch; ako teroristická činnosť vykonávaná v zahraničí agentmi komunistických režimov, s cieľom šíriť násilnú revolúciu a ako fundamentalistický islamský extrémizmus, ktorý v skutočnosti veľkú časť svojej ideológie a metód prevzal od komunizmu.</p>
        <h2>2. Ako komunistické režimy vyvážajú teror</h2>
        <p>Kým komunistické režimy používajú teror a&nbsp;spôsobujú utrpenie svojim vlastným ľuďom, zároveň podporujú teroristické organizácie pôsobiace v zahraničí za účelom podnecovania k revolúcii alebo destabilizácie konkurenčných štátov.</p>
        <p>Počas studenej vojny Sovieti aktívne podporovali širokú škálu teroristických aktivít v globálnom meradle. Čínsky komunistický režim tiež podporuje teroristické vzbury v zahraničí, rozširuje maoistické teórie revolúcie a vytvára aliancie s teroristickými organizáciami a darebáckymi režimami, ktoré sú hlavnými podporovateľmi terorizmu.</p>
        <p>Stanislav Lunev, bývalý dôstojník Hlavnej správy rozviedky (GRU), ktorý zbehol na Západ, obvinil GRU z toho, že je hlavnou organizáciou školiacou teroristov po celom svete.<a href="#_edn6" id="_ednref6">[6]</a> Mnoho extrémistických skupín, ktoré vykonávali útoky na USA, mali väzby na sovietsku tajnú službu KGB. Patria medzi ne organizácie ako Ľudový front za oslobodenie Palestíny, Japonská červená armáda, talianske Červené brigády, západonemecká Frakcia červenej armády a rôzne skupiny juhoamerických partizánov.</p>
        <p>Najvplyvnejšou formou moderného terorizmu však bol radikálny islam „vyživovaný“ sovietskym blokom, ako prostriedok k&nbsp;destabilizácii moslimského sveta.</p>
        <p>V prvej polovici 20. storočia patril Blízky východ do západnej koloniálnej sféry. Vo chvíli, keď národy v tomto regióne získali nezávislosť, Sovietsky zväz túto príležitosť využil na získanie vplyvu. Blízky východ sa dnes nachádza v zložitej a chaotickej situácii, vyplývajúcej z rozporov medzi rôznymi podobami islamu, z arabsko-izraelského konfliktu, studenej vojny, politiky obklopujúcej obchod s ropou a&nbsp;zo stretu civilizácií medzi západnou a islamskou kultúrnou sférou.</p>
        <p>Ako sa uvádza v piatej kapitole, keď v júli 1978 utiekol do Spojených štátov Ion Mihai Pacepa, bývalý generálporučík a šéf zahraničnej spravodajskej služby komunistického Rumunska, stal sa najvyššie postaveným človekom, ktorý kedy zbehol zo sovietskeho bloku. Vo svojom článku „Ruské stopy“ odhalil Pacepa veľké množstvo informácií o podpore terorizmu na Blízkom východe zo strany komunistického bloku.<a href="#_edn7" id="_ednref7">[7]</a> Citoval tu Alexandra Sacharovského, šéfa sovietskej zahraničnej rozviedky: „V dnešnom svete, kedy jadrové zbrane urobili vojenskú silu zastaranou, by sa mal stať našou hlavnou zbraňou terorizmus.“ Len v roku 1969 bolo vykonaných osemdesiatdva únosov lietadiel. Mnohé z nich uskutočnila Organizácia za oslobodenie Palestíny (OOP) s podporou Sovietov a čínskych komunistov. Pacepa sa zmienil o tom, že keď navštívil kanceláriu Sacharovského, všimol si na mape sveta záplavy malých červených vlajok. Každá vlajka predstavovala jedno unesené lietadlo. Sacharovskij sa Pacepovi chválil, že taktika únosu lietadiel je jeho vlastným vynálezom.</p>
        <p>Koncept „islamského socializmu“ začal zapúšťať korene počas studenej vojny, keď Sovietsky zväz podporoval arabské krajiny proti Izraelu. Medzi jeho zástupcami boli Jásir Arafat, ktorý od roku 1969 až do svojej smrti v roku 2004 viedol Organizáciu za oslobodenie Palestíny, a Gamál Abdel Násir Husajn, ktorý od roku 1956 do svojej smrti v roku 1970 pôsobil ako druhý egyptský prezident. Organizácia za oslobodenie Palestíny, ktorá bola podporovaná Sovietskym zväzom a Čínskou komunistickou stranou, bola zapojená do rozsiahlych teroristických aktivít.</p>
        <p>V rokoch 1968 až 1978 rumunské bezpečnostné zložky každý týždeň letecky dovážali vojenské zásoby palestínskym teroristom v Libanone. Archívy otvorené po rozpade Východného Nemecka ukazujú, že východonemecká zahraničná spravodajská služba poslala do Libanonu v roku 1983 muníciu do útočných pušiek Kalašnikov v hodnote 1 877 600 amerických dolárov. Československo poskytlo islamským teroristom 1 000 ton plastickej výbušniny bez zápachu Semtex-H.</p>
        <p>Na začiatku 70. rokov začal Jurij Andropov, vtedajší šéf sovietskej tajnej služby KGB a neskôr generálny tajomník Komunistickej strany Sovietskeho zväzu, tajnú a starostlivo naplánovanú propagandistickú kampaň, ktorá mala „zasiať semená“ protižidovskej a protiamerickej nenávisti naprieč arabským a islamským svetom. Pacepa a Ronald Rychlak v spoločnej knihe <em>Dezinformácie</em> nazvali Andropova „otcom novej éry dezinformácií“.<a href="#_edn8" id="_ednref8">[8]</a></p>
        <h2>3. Komunistické začiatky islamského extrémizmu</h2>
        <p>Aj keď Sovieti a čínski komunisti financovali mnohé teroristické organizácie na Blízkom východe, skutočne zaviesť komunizmus na územiach s hlboko zakorenenou náboženskou vierou sa ukázalo ako ťažká úloha. Úsilie Sovietskeho zväzu priamo vyviezť socialistickú revolúciu do moslimského sveta sa stretlo so zmiešanými a často iba dočasnými výsledkami.</p>
        <p>Hoci bolo mnoho štátov na Blízkom východe spriaznených so Sovietskym zväzom, len Južný Jemen a Afganistan boli, po rôzne dlhú dobu počas studenej vojny, pod vládou komunistov. V&nbsp;roku 1979 vykonal Sovietsky zväz inváziu do Afganistanu a desať rokov ho okupoval v snahe podporiť komunistický režim, ktorému predtým pomohol dostať sa k moci. V roku 1989 to Sovieti vzdali a&nbsp;stiahli sa.</p>
        <p>Aj keď komunizmus samotný nezvládol zriadiť si kontrolu nad moslimským svetom, podarilo sa mu ovplyvniť utváranie a&nbsp;vývoj súčasného islamského extrémizmu.</p>
        <p>Po útoku z 11. septembra sa hrozba islamského extrémizmu stala jednou z hlavných tém a činy Usámu bin Ládina a jeho teroristickej skupiny al-Káidy sa dostali na predné stránky novín.&nbsp; Stopy ideologického pôvodu islamského extrémizmu bin Ládina však vedú ďalej, k človeku, ktorého prezývajú&nbsp; Karl Marx radikálneho islamu.<a href="#_edn9" id="_ednref9">[9]</a></p>
        <h3>a) Sajjid Qutb: Marx islamského extrémizmu</h3>
        <p>Tvrdenie, že existuje vzťah medzi komunizmom a radikálnym islamom, sa na prvý pohľad môže zdať pritiahnuté za vlasy, ak vezmeme do úvahy, že moslimovia veria v Alaha a proroka Mohameda, pričom komunizmus je ateistický a&nbsp;jeho cieľom je odstránenie náboženskej viery. Ale teórie a metódy moderného islamského extrémizmu sú v skutočnosti úzko spojené s marxizmom-leninizmom.</p>
        <p>Priekopníkom radikálneho islamu a moderného džihádu bol Sajjid Qutb, egyptský spisovateľ a spojka medzi miestnym Moslimským bratstvom, Komunistickou internacionálou a Egyptskou komunistickou stranou.<a href="#_edn10" id="_ednref10">[10]</a>,<a href="#_edn11" id="_ednref11">[11]</a> Qutbove myšlienky boli presiaknuté komunistickou logikou a rétorikou. Qutb sa narodil v roku 1906. V 20. a 30. rokoch študoval socializmus a literatúru. V roku 1950 mal za sebou už dva roky štúdia v Spojených štátoch a po návrate do Egypta vstúpil do Moslimského bratstva.<a href="#_edn12" id="_ednref12">[12]</a></p>
        <p>Qutbizmus možno opísať ako snahu o zavedenie násilia, ktoré má zničiť starú spoločnosť, v ktorej vládne „džáhilíja“. Náboženský pojem džáhilíja znamená neznalosť náboženskej pravdy a pôvodne označoval spoločnosť pred šírením islamu. Qutb vyzýval moslimov, aby položili svoj život za boj proti džahilíja, čo údajne povedie k oslobodeniu človeka. Aby Qutb jasne vyjadril svoje predstavy o tomto boji, prišiel s novou interpretáciou pojmu džáhilíja a&nbsp;islamského poňatia džihádu.</p>
        <p>Pri zmienke o džiháde si mnohí okamžite v mysli vybavia „svätú vojnu“, ale v arabčine džihád jednoducho znamená zápasiť alebo bojovať. Pre bežných moslimov to môže znamenať buď vnútorný konflikt (osobné zdokonaľovanie), alebo obranný džihád.<a href="#_edn13" id="_ednref13">[13]</a> Qutb rozšíril túto definíciu tak, aby zahŕňala proaktívne a&nbsp;nespútané použitie násilia vo „svätej vojne“ džihádu, a položil tak jeho teoretické základy.<a href="#_edn14" id="_ednref14">[14]</a> Podľa Qutbovej filozofie je akýkoľvek sociálny systém, ktorý dodržiava svetské zákony alebo etiku, antiislámska džáhilíja. Džáhilíju považoval za najväčšiu prekážku v&nbsp;aplikovaní islamských hodnôt a práva ako pre moslimov, tak nemoslimov. Dokonca aj spoločnosť, ktorá sa vyhlasovala za moslimskú, mohla byť stále džáhilíja. Qutb považoval egyptský sociálny systém, v ktorom žil, za systém, v ktorom dominovala džáhilíja, a preto musel byť podľa neho zvrhnutý.<a href="#_edn15" id="_ednref15">[15]</a></p>
        <p>Táto interpretácia džihádu a džáhilíje zrkadlí Marxovu filozofiu triedneho boja. Qutb tvrdil, že stará spoločnosť džáhilíja bola ľuďom vnútená a v tomto procese ich okrádala o slobodu. Títo zotročení ľudia – podobne ako robotnícka trieda v marxizme – mali právo viesť džihád, aby zvrhli útlak džáhilíje. Qutb obhajoval džihád ako prostriedok oslobodenia celého ľudstva, moslimov i nemoslimov.<a href="#_edn16" id="_ednref16">[16]</a>&nbsp; Keď bola Qutbova kniha zverejnená, mnohí moslimskí vodcovia si mysleli, že Qutb zachádza príliš ďaleko a považovali jeho myšlienky za kacírstvo.<a href="#_edn17" id="_ednref17">[17]</a></p>
        <p>Qutb udržiaval stále kontakty s vodcom socialisticky ladeného Hnutia slobodných dôstojníkov Gamálom Abdelom Násirom, ktorý sa neskôr na dlho stal úradujúcim egyptským prezidentom. V roku 1952 uskutočnil Násir vojenský prevrat, aby zvrhol prozápadnú monarchiu, dynastiu Muhammada Alího. Hovorí sa, že tento socialisticko-revolučný ťah naplánoval Qutb spoločne s&nbsp;Moslimským bratstvom a Násirom. Hoci Qutb dúfal, že Násir vytvorí islamský režim, Násir sa namiesto toho vydal cestou sekularizácie a v roku 1954 začal Moslimské bratstvo potláčať.</p>
        <p>Qutb a Bratstvo sa chystali Násira zavraždiť, ale sprisahanie zlyhalo a Qutb bol uväznený. Počas prvých niekoľkých rokov vo väzení bol Qutb kruto mučený, ale keď sa podmienky uvoľnili, bolo mu dovolené písať. Vo väzení napísal dve najdôležitejšie práce svojho života – <em>V tieni koránu</em> a <em>Míľniky</em>. Tieto dve knihy, ktoré sa týkajú jeho názorov na korán, islamské dejiny, Egypt a západnú spoločnosť, v plnej šírke presadzovali protisekulárny a protizápadný extrémizmus. Qutb bol nakrátko prepustený z väzenia, ale nevyužil príležitosť na útek z Egypta a bol následne znovu uväznený. V roku 1966 bol Qutb usvedčený z účasti na sprisahaní s cieľom zavraždenia Násira a&nbsp;následne bol popravený obesením. Qutb sa s hrdosťou vydal na šibenicu a stal sa z neho náboženský mučeník.</p>
        <p>Vodca al-Káidy po smrti bin Ládina, Ajmán az-Zawahirí, veril, že Qutbova poprava bola iskrou, ktorá zapálila oheň džihádistického extrémizmu.<a href="#_edn18" id="_ednref18">[18]</a> Islamskí extrémisti často citujú Qutbove učenie a považujú sa za jeho „intelektuálnych nástupcov“, ako konštatuje William McCants, odborník na boj proti terorizmu a&nbsp;bývalý výskumný pracovník v Centre pre boj proti terorizmu vo West Pointe.<a href="#_edn19" id="_ednref19">[19]</a> Odborník na oblasť Blízkeho východu Hassan Hassan vo svojej správe o Islamskom štáte (ISIS) z roku 2016 citoval príslovie populárne medzi podporovateľmi ISIS o tom, ako sa zrodila základná doktrína teroristických skupín: „Islamský štát navrhol Sajjid Qutb, vyučoval ho Abdulláh Azzám a globalizoval ho Usáma bin Ládin.“<a href="#_edn20" id="_ednref20">[20]</a></p>
        <h3>b) Leninistický predvoj džihádu</h3>
        <p>Qutb si ďalej vypožičal marxistický koncept „falošného vedomia“, ktorý sa vzťahuje k masovému prijatiu ideálov a kultúry vládcu. Koncept hovorí, že toto „falošné vedomie“ bráni masám vnímať vlastný útlak a zvrhnúť kapitalizmus v prospech socializmu. Podľa Qutba si ľudia žijúci pod džáhilíjou neuvedomujú, že sú otrokmi, a preto sa nezapájajú do džihádu, aby sa oslobodili.<a href="#_edn21" id="_ednref21">[21]</a></p>
        <p>Jedným z Leninových hlavných diel je pamflet nazvaný „Čo robiť?“, v ktorom tvrdí, že pracujúca trieda si sama neuvedomí potrebu komunizmu, pokiaľ k&nbsp;tomu nebude dovedená elitnou skupinou revolucionárov. A pretože Qutb vo svojom ťažení proti džáhilíji stál pred rovnakou otázkou, hľadal riešenie v Leninových tézach.</p>
        <p>Qutbove spisy sú plné výrazov známych študentov marxizmu leninizmu, ako je „predvoj“, „štát“, „revolúcia“ a pod. Situácia a výzvy, ktorým Lenin čelil v čase písania svojho pamfletu, sa podobajú na okolnosti, ktorým čelil Qutb, keď formuloval svoju vlastnú radikálnu ideológiu. Lenin vložil nádej v úspešnú revolúciu do predvoja proletárskej strany – vysoko disciplinovanej, elitnej organizácie, zodpovednej za dohľad nad revolúciou a vedenie más – a vysmieval sa predstave, že by k úspechu komunizmu stačilo, aby jeho zástupcovia len pôsobili v&nbsp;celej spoločnosti. Qutb túto teóriu odkopíroval a&nbsp;iba nahradil leninistickú politickú stranu islamskými extrémistickými organizáciami.</p>
        <p>Lenin kládol veľký dôraz na dôležitosť organizácie a predvoja. Identifikoval jasný rozdiel medzi spontánnosťou a vedomím, a predstavil myšlienku budovania strany. Podľa Lenina môžu robotníci spontánnou akciou naplniť len povrchné požiadavky, ako je zvýšenie platov a osemhodinový pracovný deň, ale chýba im vedomie potrebné k oslobodeniu ľudstva. Lenin veril, že externý predvoj (zvyčajne buržoázni intelektuáli, ktorí majú privilegované vzdelanie) je potrebný na podnecovanie a indoktrináciu robotníkov, aby uverili, že jediným východiskom je revolúcia a že jedine oslobodením celého ľudstva môžu byť oslobodení aj oni sami. Aby bolo možné plne využiť predvoj, je potrebné úzke prepojenie politickej strany, ktorá prevezme úplnú kontrolu nad organizovaním jeho aktivít a poskytne jeho členom príležitosť pracovať v ilegalite ako profesionálnym revolucionárom.<a href="#_edn22" id="_ednref22">[22]</a></p>
        <p>Glenn E. Robinson, docent na Naval Postgraduate School v Monterey v Kalifornii a výskumný pracovník Centra pre štúdium Blízkeho východu na Kalifornskej univerzite – Berkeley, povedal o radikálnom islame: „Moderný džihádizmus je výrazne leninistický. Aj keď zo zjavných dôvodov džihádistickí ideológovia necitujú ako inšpiráciu Lenina, ich koncepcia a logika, najmä u Sajjida Qutba, tento vplyv prezrádza. Počas svojich štúdií v&nbsp;Egypte v 40. rokoch 20. storočia sa Qutb bezpochyby oboznámil s&nbsp;obsahom Leninových spisov. Dva Qutbove kľúčové koncepty pochádzajú priamo od Lenina: džama’a (predvoj) a manhadž (program). ... Leninovo naliehanie na tom, aby ústredie predvoja malo podrobný a&nbsp;premyslený program, podľa ktorého bude vykonávať a následne konsolidovať revolúciu, našlo tiež svoju podobu v Qutbových spisoch, len v islamskom háve.“</p>
        <p>Na základe leninizmu, z ktorého čerpal, obhajoval Qutb organizáciu moslimskej verzie leninistickej strany. „Qutb predniesol presne rovnaký argument pre moslimský svet,“ napísal Robinson. „Drvivá väčšina moslimov bola príliš pohltená a skorumpovaná systémom nespravodlivej a&nbsp;antiislamskej vlády, aby vedela, ako a kedy pozdvihnúť zbrane proti štátu. Zanietený predvoj džihádistických kádrov bol potrebný k organizácii priamych akcií proti štátu.“<a href="#_edn23" id="_ednref23">[23]</a></p>
        <p>Tento predvoj, ktorý tvoria extrémisti, čiže tí, ktorých Qutb nazýva „pravými moslimami“, má revolučné poslanie oslobodiť všetkých moslimov a celú ľudskú civilizáciu. Predvoj musí tvrdo zakročiť voči falošným moslimom, riadiť sa islamskou ideológiou určenou Qutbovou interpretáciou, založiť nový národ založený na islamizme a&nbsp;použiť násilie k vnúteniu islamu zvyšku sveta.</p>
        <p>Okrem leninistického predvoja zahŕňala Qutbova teória tiež utopistické ideály, ako je „sociálna rovnosť“ a eliminácia tried.<a href="#_edn24" id="_ednref24">[24]</a> Tieto body sa zhodujú s cieľmi stanovenými v komunizme.</p>
        <p>Po Qutbovej smrti pokračoval vo vydávaní kníh jeho brat Muhammad Qutb. Kniha <em>Ma</em>’<em>arakat ul-Islam war-Ra</em>’<em>samaaliyyah</em>, vydaná v roku 1993, znovu odhaľuje, ako bol Qutb inšpirovaný komunizmom. Qutb na rovinu vyhlasuje, že islam je „jedinečné, konštruktívne a&nbsp;pozitivistické ‚aqidah‘ ​​[vyznanie], ktoré bolo modelované a&nbsp;sformované z kresťanstva a komunizmu, ide o tú najlepšiu zmes zahŕňajúcu všetky ich (t. j. kresťanské a komunistické) ciele a navyše k&nbsp;nim dodáva harmóniu, rovnováhu a spravodlivosť“.<a href="#_edn25" id="_ednref25">[25]</a></p>
        <h3>c) Komunistické jadro islamského extrémizmu</h3>
        <p>Triedny boj je ďalšia marxistická myšlienka, ktorá tvorí ústredný bod islamského extrémizmu. Marx strávil celý svoj život snahou vyvolať konflikt medzi proletariátom a buržoáziou, aby tento konflikt zosilnel až do bodu, z ktorého niet návratu, a nakoniec sa „vyriešil“ revolúciou. Islamskí extrémisti pracujú v mnohých ohľadoch identickým spôsobom.</p>
        <p>Zničenie Svetového obchodného centra na Manhattane samo osebe nijako neprispelo k realizácii zjednotenia moslimského sveta, ktoré si Qutb predstavoval, ale slúžilo ako prostriedok na prehĺbenie konfliktu medzi západným a moslimským svetom. Teroristické útoky mali vyvolať silný odpor Západu voči moslimom, čo by spätne podnietilo moslimov k ďalším útokom.<a href="#_edn26" id="_ednref26">[26]</a> Extrémistické metódy zrkadlia konflikty, ktoré medzi proletariátom a buržoáziou podnecovali Marx a Lenin, aby vytvorili podmienky potrebné na začatie revolúcie.</p>
        <p>Qutbove teórie sa ďaleko viac podobajú komunizmu ako tradičnému islamu. Aj keď sa islamskí extrémisti hlásia k&nbsp;náboženskej opozícii voči komunizmu, v skutočnosti absorbovali čisté základy komunistickej revolučnej doktríny. Ako poznamenal novinár Chuck Morse: „Skutočným nepriateľom, s ktorým je slobodný svet konfrontovaný, zostáva komunizmus. ... Radikálny islam nie je ničím iným ako komunizmom zamaskovaným v&nbsp;tradičnom háve islamu. Ten istý komunistický nepriateľ, ktorý rozvrátil Európu ... sa zakorenil v islamskom svete a premenil veľkú časť islamskej elity.“<a href="#_edn27" id="_ednref27">[27]</a></p>
        <p>Fínsky politický historik Antero Leitzinger verí, že moderný terorizmus sa zrodil v rokoch 1966 až 1968 a vyvíjal sa súčasne s medzinárodným komunistickým hnutím. V 60. rokoch 20. storočia, kedy na Západe prepuklo neovládateľné šialenstvo radikálneho študentského hnutia, sa mnoho zahraničných výmenných študentov z moslimského sveta dostalo do kontaktu s ľavicovými myšlienkami a priniesli si so sebou domov ľavicové koncepty, ako je násilná revolúcia.<a href="#_edn28" id="_ednref28">[28]</a></p>
        <p>V roku 1974 sa Abdallah Schleifer, moslimský konvertita, ktorý sa neskôr stal profesorom mediálnych štúdií na Americkej univerzite v Káhire, stretol s Ajman az-Zawáhirím, budúcim vodcom al-Káidy. Zawáhirí, ktorý v tom čase študoval medicínu na Káhirskej univerzite, sa chválil tým, že islamské extrémistické skupiny nabrali najviac členov z elitných inštitúcií, ako sú lekárske a&nbsp;inžinierske školy. Schleifer uviedol, že počas 60. rokov bola na týchto školách vysoká koncentrácia mladých marxistov a že radikálny islam bol teda len nový trend študentských povstaní. Schleifer povedal Zawáhirímu: „Počúvaj, Ajman, som bývalý marxista. Keď hovoríš, mám pocit, akoby som bol späť v strane. Nemám pocit, že som s tradičným moslimom.“<a href="#_edn29" id="_ednref29">[29]</a></p>
        <p>Je prekvapujúce, že mnohí pozorovatelia spájajú islamský extrémizmus s fašizmom (islamofašizmus) a z rôznych dôvodov nezmieňujú jeho komunistický pôvod. Fašizmus je forma národného socializmu a socializmus je prvou fázou komunizmu, ako povedal Lenin a ďalší. Komunizmus má medzinárodný dosah a usiluje o&nbsp;komunistické revolúcie po celom svete. Keď sa pozrieme na islamský extrémizmus z hľadiska jeho celkového prístupu a doktríny, je zrejmé, že má oveľa viac spoločného s komunizmom.</p>
        <h3>d) Qutb a vzostup terorizmu</h3>
        <p>Qutbove spisy ovplyvnili mnoho mladých Arabov, vrátane palestínskeho učenca a spoluzakladateľa al-Káidy, Abdulláha Júsufa Azzáma.<a href="#_edn30" id="_ednref30">[30]</a> „Správa Komisie ku 11. septembru“ (The 9/11 Commission Report) zmieňuje Azzáma ako „Qutbovho žiaka“ a naznačila vplyv Qutba na svetonázor bin Ládina.<a href="#_edn31" id="_ednref31">[31]</a></p>
        <p>Muhammad Qutb, mladší brat Sajjida Qutba, bol tiež jedným z hlavných šíriteľov Qutbových myšlienok. Muhammad Qutb neskôr odišiel do Saudskej Arábie a stal sa profesorom. Bol zodpovedný za editovanie, publikovanie a propagáciu teórií svojho zosnulého brata. Bin Ládin sa pravidelne zúčastňoval týždenných verejných prednášok Muhammada Qutba a čítal Qutbove knihy.</p>
        <p>Zawáhirí povedal, že keď bol mladý, opakovane počul od svojho strýka, aký je Qutb skvelý a ako trpel vo väzení.<a href="#_edn32" id="_ednref32">[32]</a> V roku 1966, kedy bol Qutb obesený, pomohol pätnásťročný Zawáhirí vytvoriť podzemnú militantnú bunku, ktorá si kládla za cieľ zvrhnúť vládu a&nbsp;založiť Islamský štát. Zawáhirí vo svojich pamätiach napísal: „Násirský režim si myslel, že islamské hnutie dostalo smrtiacu ranu popravou Sajjida Qutba a jeho súdruhov. ... Ale zdanlivý pokoj na povrchu ukrýval okamžitú interakciu s myšlienkami Sajjida Qutba a formovanie jadra moderného islamského džihádistického hnutia v Egypte.“<a href="#_edn33" id="_ednref33">[33]</a> Potom sa Zawáhirí pripojil k egyptskej skupine <em>Islamský džihád</em>, ktorá sa sformovala v 70. rokoch a stal sa mentorom bin Ládina a dôležitým členom al-Káidy. Nakoniec, po smrti bin Ládina, prevzal vedenie al-Káidy.</p>
        <p>V sunnitskom moslimskom svete je Qutb najvýznačnejším radikálnym mysliteľom.<a href="#_edn34" id="_ednref34">[34]</a> Prakticky všetky koncepty a inovácie sunnitských džihádistických skupín možno nájsť v jeho textoch.<a href="#_edn35" id="_ednref35">[35]</a> Hoci sa rôzne skupiny džihádistov môžu líšiť svojou formou, všetky zdieľajú jeden spoločný bod, a to použitie násilia pod vlajkou islamu k uskutočneniu vlastných politických cieľov.<a href="#_edn36" id="_ednref36">[36]</a></p>
        <p>Vražda egyptského prezidenta Anwara as-Sadáta v roku 1981 egyptským Islamským džihádom a útoky egyptskej teroristickej skupiny al-Gamma al-Islamija proti vládnym úradníkom, sekulárnym intelektuálom, egyptským kresťanom a turistom v 90. rokoch, sú všetko postupnými krokmi v kampani, ktorá mala naplniť Qutbovu víziu.<a href="#_edn37" id="_ednref37">[37]</a></p>
        <p>Radikálne džihádistické skupiny, ktoré nasledujú Qutbovu ideológiu, sa radia medzi teroristov salafistického džihádizmu. Podľa správy americkej výskumnej inštitúcie Rand Corporation, bolo v roku 2013 po celom svete takmer päťdesiat salafistických džihádistických skupín, z ktorých väčšina pôsobila v Severnej Amerike a Levante (východnom Stredomorí).<a href="#_edn38" id="_ednref38">[38]</a> Robert Manne, autor knihy <em>Myseľ Islamského štátu: ISIS a ideológie kalifátu</em> (The Mind of the Islamic State: ISIS and the Ideology of the Caliphate), nazval Qutba „otcom politického hnutia 20. storočia, ktorému dnes hovoríme salafistický džihád“ a predchodcom teroristickej skupiny Islamský štát. Dodal tiež, že aj keď Qutb nebol priamo zodpovedný za vznik Islamského štátu, „vytýčil prvý míľnik na ceste, ktorá tam nakoniec viedla“.<a href="#_edn39" id="_ednref39">[39]</a></p>
        <p>Keď sa pozrieme na rôzne extrémistické islamské organizácie súčasnosti, hoci im chýba jednotná vízia a vyžívajú sa vo vnútorných rozbrojoch, predsa len majú jednu vlastnosť, ktorá je spoločná pre drvivú väčšinu z nich: v zásade zdedili Qutbovu agresívnu formu džihádu – komunistickú revolúciu v inej forme.</p>
        <h3>e) Ako komunizmus prenasleduje bežných moslimov</h3>
        <p>Napriek tomu, že extrémistické skupiny pôsobia v&nbsp;mene islamu, ich najväčšou obeťou je práve moslimská spoločnosť. Je to preto, že bez ohľadu na povrchné výhovorky je skutočnou motiváciou terorizmu – a rovnako tak i komunizmu – túžba po zabíjaní a ničení.</p>
        <p>Správa „Islam a vzorce terorizmu a násilného extrémizmu“ z roku 2017, vydaná Centrom pre strategické a medzinárodné štúdie, tvrdí, že „extrémistické útoky vedú vo väčšine prípadov k tomu, že moslimovia zabíjajú a zraňujú iných moslimov“.</p>
        <p>Podľa tejto správy „k 83&nbsp;% [islamských extrémistických] útokov a k&nbsp;90&nbsp;% úmrtí došlo v rýdzo islamských krajinách“ a rovnako tak je tomu so samovražednými útokmi realizovanými pešími alebo za použitia vozidiel. Keď sa pozrieme na päť najhorších zločineckých hnutí vo svete v roku 2016, štyri z nich sú hnutia „islamistických“ extrémistov. Celkom 88&nbsp;% z&nbsp;2&nbsp;916 útokov a&nbsp;99&nbsp;% zo 14&nbsp;017 úmrtí, ktoré spôsobilo týchto päť najväčších páchateľov, vykonali „islamistické extrémistické skupiny“.<a href="#_edn40" id="_ednref40">[40]</a></p>
        <p>Správa Ministerstva zahraničných vecí USA o terorizme v&nbsp;jednotlivých krajinách za rok 2018 uvádza, že celkovo 8&nbsp;093 teroristických útokov, ktoré sa v tom roku vo svete uskutočnili, viedlo k&nbsp;32&nbsp;836 úmrtiam. Útoky sa v drvivej väčšine udiali v krajinách a oblastiach, kde prevláda moslimská väčšina: „V roku 2018 došlo k teroristickým incidentom v 84 krajinách a oblastiach. Asi 85&nbsp;% všetkých incidentov sa sústredilo do troch geografických regiónov: Blízky východ, južná Ázia a&nbsp;subsaharská Afrika. Najviac prípadov teroristických útokov v roku 2018 sa odohralo v nasledujúcich krajinách (v zostupnom poradí): Afganistan, Sýria, Irak, India, Nigéria, Somálsko, Filipíny, Pakistan, Jemen a Kamerun. Incidenty v týchto desiatich krajinách tvoria 71&nbsp;% celkového počtu prípadov a&nbsp;81&nbsp;% všetkých úmrtí v dôsledku teroristických útokov.“<a href="#_edn41" id="_ednref41">[41]</a></p>
        <p>Naproti tomu teroristické útoky vedené v západných krajinách vyústili v oveľa menší počet obetí na životoch. Štúdia vykonaná organizáciou Cato Institute v roku 2019 uvádza, že teroristi narodení v zahraničí boli v USA zodpovední za 3&nbsp;037 z&nbsp;3&nbsp;518 vrážd, ktoré vykonali teroristi na americkej pôde od roku 1975 do roku 2017. Toto číslo zahŕňa 2&nbsp;979 ľudí zabitých pri útokoch z 11. septembra 2001.<a href="#_edn42" id="_ednref42">[42]</a></p>
        <h2>4. Podpora terorizmu&nbsp; Čínskou komunistickou stranou</h2>
        <p>Čínska komunistická strana dlhodobo podporuje teroristické aktivity v zahraničí. Stála i v pozadí aktivít palestínskeho teroristického vodcu Jásira Arafata. Arafat bol jedným z prvých, kto využil taktiku únosu lietadiel komerčných leteckých spoločností, zameriaval sa na americké sily a stal sa inšpiráciou pre Usámu bin Ládina.</p>
        <h3>a) Podpora teroristických aktivít Jásira Arafata zo strany čínskeho&nbsp;&nbsp; režimu</h3>
        <p>Arafat v roku 1959 založil Palestínske národné oslobodenecké hnutie, tiež známe ako Fatah a v roku 1988 vyhlásil Palestínu za nezávislý štát. Až do svojej smrti v roku 2004 bol vedúcou postavou rôznych palestínskych militantných organizácií. Arafat bol pre Čínsku komunistickú stranu zrejme najobľúbenejšou osobou z&nbsp;Blízkeho východu. Navštívil Čínu 14-krát a stretol sa takmer s&nbsp;každým čínskym komunistickým vodcom, vrátane Mao Ce-tunga, Čou En-laja, Teng Siao-pchinga a Ťiang Ce-mina.</p>
        <p>V roku 1964 založil Arafat skupinu al-‘Asifah (Búrka), vojenské krídlo hnutia Fatah. Bezprostredne potom odcestoval do Pekingu, aby sa stretol s čínskym premiérom Čou En-lajom. Čou mu pripomenul, aby venoval pozornosť svojej stratégii a nepoužíval kontraproduktívne heslá, ako napríklad tie, ktorá volajú po úplnom zničení Izraela.<a href="#_edn43" id="_ednref43">[43]</a></p>
        <p>Okrem poskytovania zbraní a finančnej podpory Peking často inštruoval Palestínu v otázkach, ako viesť konflikt so Spojenými štátmi a Izraelom a zároveň rozširovať svoj vplyv na medzinárodnej scéne. Komunistická strana tiež pozvala Palestínčanov, aby absolvovali školenie v Číne.</p>
        <p>V januári 1965 začal Arafat v severnej Palestíne vojnu proti Izraelu pomocou svojej partizánskej organizácie. V máji toho istého roku si zriadila Organizácia pre oslobodenie Palestíny kanceláriu v Pekingu. Čína týmto bezprecedentným krokom poskytla úradu OOP diplomatické zaobchádzanie a otvorene podporovala OOP na rôznych medzinárodných akciách.</p>
        <p>Hneď potom, čo v novembri 1988 Arafat vyhlásil nezávislosť Palestínskeho štátu, Peking štát okamžite uznal a nadviazal s ním diplomatické vzťahy.</p>
        <p>Arafat a vtedajší generálny tajomník čínskej komunistickej strany Ťiang Ce-min sa v rokoch 2000 a 2001 navzájom navštevovali. Práve v tom čase medzi Palestínou a Izraelom vypukli krvavé konflikty veľkého rozsahu. Izrael opakovane odsúdil Arafata za jeho úlohu v páchanom násilí. Arafat dokázal s podporou čínskych komunistov zápasiť so Spojenými štátmi a Izraelom a ďalej narúšať stabilitu Blízkeho východu.</p>
        <p>Členské organizácie OOP boli zapojené do rôznych otvorených i podzemných militantných teroristických aktivít. Ich zástupcovia tvrdili, že násilná revolúcia je jediným spôsobom, ako oslobodiť krajinu. Táto ideológia nasleduje rovnakú doktrínu ako komunistické hnutia. Arafat mal úzke väzby na vodcov v komunistických krajinách a stretával sa so zástupcami Socialistickej internacionály. Fatah bol pozorovateľom v Strane európskych socialistov.</p>
        <p>V roku 1970 vykonala OOP pokus o atentát na jordánskeho kráľa Husajna bin Talála a tiež sa pokúsila zvrhnúť jordánsku vládu, čo sa jej nepodarilo. V septembri toho istého roku OOP uniesla päť komerčných lietadiel v rámci tzv. únosov na Dawson’s Field. Štyri z nich smerovali do New Yorku a jedno do Londýna. Jeden z teroristov vyhlásil, že únos lietadla má väčší účinok ako zabitie stovky Izraelčanov v bitke.</p>
        <p>V roku 1972 teroristická skupina Čierny september, militantná frakcia organizácie Fatah, zmasakrovala jedenásť izraelských atlétov na olympijských hrách v Mníchove. Jedným z teroristov bol Alí Hasan Salame, veliteľ bezpečnosti hnutia Fatah a jeden z&nbsp;Arafatových najdôvernejších poručíkov. Okrem Izraelčanov zabitých pri útoku zomrel tiež západonemecký policajt.<a href="#_edn44" id="_ednref44">[44]</a></p>
        <p>Spojené štáty a Izrael označili Arafata za muža, ktorý stojí za radom teroristických útokov na Blízkom východe. V roku 1987 Biely dom klasifikoval Fatah a OOP ako teroristické organizácie a uzavrel Palestínsku informačnú kanceláriu OOP vo Washingtone.&nbsp;</p>
        <h2>b) Väzby medzi ČKS a al-Káidou</h2>
        <p>Pre drvivú väčšinu ľudí na celom svete znamenal &nbsp;11. september 2001 šok a tragédiu. Ale v Číne boli reakcie úplne iné. Od internetových fór a chatovacích miestností po univerzitné jedálne stálo veľké množstvo ľudí na strane teroristov s komentármi ako „Dobrá práca!“ alebo „Dôrazne podporujeme spravodlivé skutky proti Spojeným štátom“. Podľa prieskumu na čínskej internetovej stránke NetEase, ktorého sa zúčastnilo 91 701 osôb, vyjadrilo len 17,8&nbsp;% respondentov silný nesúhlas s teroristickými útokmi, zatiaľ čo väčšina ľudí si v súvislosti s katastrofou vybrala možnosť „opozície voči Spojeným štátom“ alebo „to najlepšie ešte len príde“.<a href="#_edn45" id="_ednref45">[45]</a></p>
        <p>Číňania, ktorí fandili teroristickým útokom, nemajú s bin Ládinom ani radikálnymi moslimami nič spoločné, čo sa týka etnickej príslušnosti, náboženstva či kultúrneho zázemia, ale korene ich jedovatého myslenia boli totožné. Rovnako ako keď islamskí extrémisti viedli „svätú vojnu“ proti nemoslimskému svetu i proti „falošným“ moslimom, o ktorých povedali, že boli oklamaní džáhilíjou, ČKS strávila 70 rokov tým, že Číňanov indoktrinovala nenávisťou voči triednym nepriateľom a vštepila im prekrútenú kultúru komunistickej strany.</p>
        <p>Spoločné rysy islamského extrémizmu a komunizmu nekončia tým, že sa prekrývajú ich ideológie. Čínska komunistická strana si udržiavala úzke vzťahy s Talibanom a&nbsp;s al-Káidou aj potom, čo sa Taliban chopil moci v Afganistane, rovnako ako v období, keď Taliban poskytoval ochranu bin Ládinovi. V roku 1998, potom čo Spojené štáty zaútočili na základne al-Káidy riadenými strelami, čínsky režim údajne zaplatil bin Ládinovi 10 miliónov dolárov za nevybuchnuté americké rakety, pravdepodobne preto, aby ukradol technológiu.<a href="#_edn46" id="_ednref46">[46]</a> <a href="#_edn47" id="_ednref47">[47]</a></p>
        <p>Čínsky režim zároveň pokračoval v poskytovaní citlivých vojenských technológií štátom podporujúcim terorizmus. Na konci roku 2000 navrhla Bezpečnostná rada OSN sankcie voči Talibanu s cieľom prinútiť ho uzavrieť bin Ládinove teroristické výcvikové tábory umiestnené na jeho území, ale Čína sa hlasovania zdržala. Namiesto toho vyslala vojenský personál, aby podporili Taliban, ihneď potom, čo americká armáda začala letecké útoky v Afganistane.</p>
        <p>Po 11. septembri americké spravodajské agentúry oznámili, že ZTE a Huawei, dve čínske technologické spoločnosti s väzbami na čínsku armádu, pomáhajú armáde Talibanu zriadiť telefónnu sieť v hlavnom meste Afganistanu, Kábule.<a href="#_edn48" id="_ednref48">[48]</a> V deň útokov 11. septembra 2001 podpísali čínski a talibanskí úradníci zmluvu o&nbsp;rozšírení hospodárskej a vedeckej spolupráce.<a href="#_edn49" id="_ednref49">[49]</a></p>
        <p>V knihe <em>Neobmedzená vojna</em> z roku 1999 dvaja čínski vojenskí dôstojníci diskutovali o tom, že ak by bolo Svetové obchodné centrum v New Yorku znovu napadnuté po vzore neúspešného bombového útoku z roku 1993, vytvorilo by to pre USA zložitú dilemu. Autori tiež priamo menovali al-Káidu ako organizáciu schopnú takú operáciu previesť.<a href="#_edn50" id="_ednref50">[50]</a> Či už ČKS o tom, čo sa stane 11. septembra, vedela vopred alebo nie, koncept „neobmedzeného boja“ čínskeho režimu poskytol teoretické vedenie pre budúce operácie Usámu bin Ládina.</p>
        <p>V roku 2004 vyšlo najavo, že čínske tajné služby používali fiktívne spoločnosti na finančných trhoch po celom svete, aby pomohli bin Ládinovi získať finančné prostriedky a prať špinavé peniaze.<a href="#_edn51" id="_ednref51">[51]</a> S pádom Berlínskeho múru stál komunistický tábor pred úplným kolapsom a Čínska komunistická strana bola náhle ponechaná, aby sama čelila obrovskému tlaku slobodného sveta. Práve keď sa Spojené štáty a slobodný svet chystali odsúdiť komunistickú tyraniu, došlo k udalostiam 11. septembra. Priority sa dramaticky zmenili, a namiesto toho vypukla vojna proti terorizmu. To pomohlo Čínskej komunistickej strane získať oddychový čas a umožnilo komunizmu znovu expandovať.</p>
        <p>Hrozba radikálneho islamského terorizmu donútila Spojené štáty odkloniť svoje zdroje a pozornosť od pokračujúcej hrozby komunizmu, čím zabránila slobodnému svetu postaviť sa zoči-voči bezprecedentným zločinom ČKS. Zatiaľ čo západný svet viedol vojnu na Blízkom východe, medzi Čínou a Spojenými štátmi v tichosti dochádzalo k presunu obrovského bohatstva, čo umožnilo vznik ďalšej komunistickej superveľmoci tam, kde zlyhal Sovietsky zväz.</p>
        <h1>5. Konvergencia terorizmu a západnej radikálnej ľavice</h1>
        <p>Radikálni ľavicoví intelektuáli na Západe nad útokmi 11. septembra jasali a páchateľov sa zastávali. Niekoľko dní po útokoch taliansky dramatik a nositeľ Nobelovej ceny za literatúru povedal: „Veľkí špekulanti sa vyžívajú v ekonomike, ktorá každý rok zabije desiatky miliónov ľudí chudobou – takže čo znamená 20-tisíc mŕtvych v&nbsp;New Yorku?“<a href="#_edn52" id="_ednref52">[52]</a> Profesor na University of Colorado – Boulder charakterizoval tých, ktorí zomreli vo Svetovom obchodnom centre, ako „malých Eichmannov“ (Eichmann bol jedným z architektov nacistického holokaustu). Tvrdil, že obete boli súčasťou „technokratických oddielov v samom srdci amerického globálneho finančného impéria“, a naznačoval, že útoky boli spravodlivým trestom.<a href="#_edn53" id="_ednref53">[53]</a></p>
        <p>11. februára 2003, mesiac predtým, ako Spojené štáty zaútočili na Irak, zverejnil bin Ládin zvukovú nahrávku prostredníctvom televízie Al Jazeera, v ktorej uviedol, že „nikomu neublíži, keď sa záujmy moslimov spoja so záujmami socialistov v boji proti križiakom“, a vyzval ľudí, aby vyšli do ulíc bojovať proti americkej armáde.<a href="#_edn54" id="_ednref54">[54]</a></p>
        <p>V nádeji, že zabránia Spojeným štátom viesť vojenské zásahy v Afganistane a Iraku a že neskôr prekazia ich snahy viesť vojnu proti teroru, začali rôzne radikálne ľavicové sily rozsiahle protivojnové protestné hnutie. Väčšinu členov významnej protivojnovej organizácie ANSWER (Act Now to Stop War and End Racism, v&nbsp;preklade „Konajte hneď, aby ste zastavili vojnu a ukončili rasizmus“), ktorá bola založená v roku 2001, tvoria socialisti, komunisti a&nbsp;stúpenci ľavice alebo členovia progresívnych hnutí. Mnoho z jej zakladateľov má väzby na Medzinárodné akčné centrum a Svetovú stranu pracujúcich, radikálnu komunistickú organizáciu, ktorá je spojencom režimu v Severnej Kórei. ANSWER je teda frontovou silou názorovo spätou so stalinistickým komunizmom. Ďalším hnutím podieľajúcim sa na protivojnovom hnutí bola skupina s názvom <em>Nie v našom mene</em> (Not in Our Name), popredná organizácia Revolučnej komunistickej strany, ktorá je marxisticko-leninistickou stranou napojenou na čínsky komunistický režim.<a href="#_edn55" id="_ednref55">[55]</a></p>
        <p>Kniha amerického odborníka Davida Horowitza z&nbsp;roku 2004 nazvaná <em>Zvrátená aliancia: Radikálny islam a&nbsp;americká ľavica </em>(Unholy Alliance: Radical Islam and the American Left) odhaľuje nebezpečnú spojitosť medzi islamskými extrémistami a radikálnou ľavicou. Podľa jeho analýzy radikálna ľavica po celom svete nepriamo slúžila na krytie islamských džihádistov.<a href="#_edn56" id="_ednref56">[56]</a></p>
        <p>Na stretnutí s predstaviteľmi Hizballáhu povedal prominentný ľavicový profesor, že Spojené štáty sú „jedným z popredných teroristických štátov“.<a href="#_edn57" id="_ednref57">[57]</a> Istý odborný asistent v odbore antropológie na Kolumbijskej univerzite predniesol pred publikom 3&nbsp;000 študentov, že by „rád osobne videl jeden milión Mogadišanov“.<a href="#_edn58" id="_ednref58">[58]</a> V bitke o&nbsp;Mogadišo v roku 1993 prepadli somálski bojovníci, údajne vycvičení al-Káidou, špeciálne jednotky USA a zabili 18 amerických vojakov. Profesor tiež vyjadril nádej, že americkí vojaci sa navzájom pozabíjajú.</p>
        <p>Niektorí ľavicoví stúpenci pomáhali teroristom priamo. V&nbsp;roku 1995 bol Omar Abdel-Rahman odsúdený za to, že vykonal bombový útok na Svetové obchodné centrum. Rahmanova obhajkyňa Lynne Stewartová bola v roku 2006 odsúdená na odňatie slobody za to, že pomohla prepašovať správu od Abdel-Rahmana jeho nasledovníkom na Blízkom východe. Abdel-Rahman svojich stúpencov v&nbsp;správe vyzýval, aby pokračovali v teroristickej činnosti. Stewartová sa stala politickým idolom ľavice a bola opakovane pozývaná, aby prednášala na univerzitných kampusoch.<a href="#_edn59" id="_ednref59">[59]</a></p>
        <p>Stáť na strane teroristov – proti západnej demokratickej spoločnosti – je súčasťou dlhého pochodu radikálnej ľavice, ktorej cieľom je zničiť a ovládnuť západnú spoločnosť zvnútra. Ľavica je ochotná použiť akúkoľvek metódu, ktorá jej pomôže tento cieľ dosiahnuť. Hoci ľavicová ideológia nemá s islamským extrémizmom na prvý pohľad nič spoločné, na hlbšej úrovni majú spoločné korene, ktoré vychádzajú z nenávisti a boja ducha komunizmu.</p>
        <h1>6. Odstránenie základného koreňa terorizmu</h1>
        <p>Od Parížskej komúny a Leninovej inštitucionalizácie násilia, až po komunistickou vládou riadené prenasledovanie ľudí v Číne, komunizmus vždy používal terorizmus na dosiahnutie svojich cieľov. Teroristi používajú násilie na to, aby spoločnosť uvrhli do chaosu, a používajú strach, aby ľudí dostali pod svoju kontrolu. Porušujú morálne hodnoty, ktoré sú ľudstvom všeobecne zastávané, aby dosiahli svoje vlastné ciele.</p>
        <p>Korene komunizmu môžeme vidieť v základných myšlienkach a metódach moderných teroristických skupín, pretože komunistická ideológia poskytuje pre ich zlú agendu teoretický rámec.</p>
        <p>Okrem toho komunizmus mimo území ovládaných komunistickými režimami manipuloval s celým radom skupín a jednotlivcov, aby vykonávali teroristickú činnosť, rozsievali po celom svete chaos a vytvárali diverznú dymovú clonu, ktorá mala zmiasť nepriateľov. Radikálny islamský terorizmus je stredom pozornosti v&nbsp;rámci medzinárodných konfliktov od konca studenej vojny. Kým však boli Spojené štáty a ich spojenci zapletení v nákladných a&nbsp;zdĺhavých kampaniach na Blízkom východe, čínsky komunistický režim ticho pracoval na tom, aby sa stal superveľmocou schopnou zvádzať boj so slobodným svetom. Chaos, ktorý vládol na Blízkom východe aj inde, odviedol pozornosť západných vlád a verejnosti od novej hrozby komunizmu i bezprecedentných zločinov proti ľudskosti, ktoré vykonáva ČKS napriek väčším ekonomickým a&nbsp;kultúrnym väzbám so Západom.</p>
        <p>Komunizmus, ktorý je založený na nenávisti a boji, je základnou príčinou terorizmu po celom svete. Zatiaľ čo médiá zameriavajú svoju pozornosť na teroristické útoky, ktoré zasahujú západnú spoločnosť, prevažná väčšina ľudí zabitých islamskými extrémistami sú bežní moslimovia žijúci v islamských krajinách. Podobne v prípade viac ako sto miliónov úmrtí zapríčinených komunizmom, išlo takmer vždy o ľudí žijúcich priamo pod vládou komunistických režimov.</p>
        <p>Pokiaľ nebudú jedovaté korene komunizmu vykopané, ľudstvo nebude mať ani jediný deň pokoja. Len ak ľudia rozpoznajú úlohu komunizmu v teroristických aktivitách, ktoré sužujú náš svet a postavia sa na stranu tradičných morálnych hodnôt a viery, môže byť táto hrozba porazená a „globálna vojna proti teroru“ úspešne ukončená.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><a href="#_ednref1" id="_edn1">[1]</a> Brian Whitaker, „<em>The Definition of Terrorism“</em>, The Guardian, 7. mája 2001, <a href="https://www.theguardian.com/world/2001/may/07/terrorism.">https://www.theguardian.com/world/2001/may/07/terrorism.</a></p>
        <p><a href="#_ednref2" id="_edn2">[2]</a> Karl Kautsky, <em>Terrorism and Communism: A Contribution to the Natural History of Revolution, prel. </em>W. H. Kerridge (Manchester, United Kingdom: The National Labour Press Ltd., 1919), Marxists Internet Archive, stránka navštívená 5. mája 2020,</p>
        <p>https: // www.marxists.org/archive/kautsky/1919/terrcomm/index.htm.</p>
        <p><a href="#_ednref3" id="_edn3">[3]</a> Felix Dzerzhinsky, citácie podľa: Michael Foley, <em>Russian Civil War: Red Terror, White Terror,</em> 1917–1922 (United Kingdom: Pen &amp; Sword Books, 2018).</p>
        <p><a href="#_ednref4" id="_edn4">[4]</a> Sergei Melgunov, <em>The Red Terror in Russia</em> (United Kingdom: Hyperion Press, 1975), kap. 3.</p>
        <p><a href="#_ednref5" id="_edn5">[5]</a> Deborah Seward, „Statue of Soviet Intelligence Chief Pulled Down“, <em>The Associated Press,</em> 22. augusta 1991, https:// apnews.com / 863f51d5087d19bee14a280626730385.</p>
        <p><a href="#_ednref6" id="_edn6">[6]</a> Stanislav Lunev, <em>Through the Eyes of the Enemy: The Autobiography of Stanislav Lunev</em> (Washington, DC: Regnery Publishing, Inc., 1998), 80.</p>
        <p><a href="#_ednref7" id="_edn7">[7]</a> Ion Mihai Pacepa, „Russian Footprints“, <em>National Review,</em> 24. augusta 2006, <a href="https://www.nationalreview.com/2006/08/russian-footprints-ionmihai-pacepa">https://www.nationalreview.com/2006/08/russian-footprints-ionmihai-pacepa</a></p>
        <p><a href="#_ednref8" id="_edn8">[8]</a> Ion Mihai Pacepa a Ronald Rychlak, <em>Disinformation: Former Spy Chief Reveals Secret Strategies for Undermining Freedom, Attacking Religion, and Promoting Terrorism</em> (Washington, DC: WND Books, 2013), 259–266.</p>
        <p><a href="#_ednref9" id="_edn9">[9]</a> Paul Berman, „The Philosopher of Islamic Terror“, <em>New York Times Magazine</em>, 23. marca 2003, <a href="https://www.nytimes.com/2003/03/23/magazine">https://www.nytimes.com/2003/03/23/magazine</a> / the-philosopher-of-islamic-terror.html.</p>
        <p><a href="#_ednref10" id="_edn10">[10]</a> Raymond Ibrahim, „Ayman Zawahiri and Egypt: A Trip Through Time“, <em>The Investigative Project on Terrorism</em>, 30. novembra 2012,</p>
        <p><a href="https://www.investigativeproject.org/3831/ayman-zawahiri-andegypt-a-trip-through-time.">https://www.investigativeproject.org/3831/ayman-zawahiri-andegypt-a-trip-through-time.</a></p>
        <p><a href="#_ednref11" id="_edn11">[11]</a> Robert R. Reilly, <em>The Roots of Islamist Ideology,,</em> Centre for Research Into Post-Communist Economies, február 2006, 4.</p>
        <p><a href="#_ednref12" id="_edn12">[12]</a> Berman, „The Philosopher“.</p>
        <p><a href="#_ednref13" id="_edn13">[13]</a> Andrew McGregor, „Al-Qaeda’s Egyptian Prophet: Sayyid Qutb and the War on Jahiliya“, <em>Terrorism Monitor</em> 1, č. 3 (4. mája 2005), https: //jamestown.org/program/al-qaedas-egyptian-prophet-sayyid-qutb-and-the-war-on-jahiliya.</p>
        <p><a href="#_ednref14" id="_edn14">[14]</a> A. E. Stahl, „'Offensive Jihad' in Sayyid Qutb’s Ideology“, International Institute for Counter-Terrorism, 24. marca 2011, <a href="https://www.ict.org.il/Article">https://www.ict.org.il/Article</a> / 1097 / Offensive-Jihad-in-Sayyid-Qutbs-Ideology # gsc.tab = 0.</p>
        <p><a href="#_ednref15" id="_edn15">[15]</a> McGregor, „Al-Qaeda’s Egyptian Prophet.“</p>
        <p><a href="#_ednref16" id="_edn16">[16]</a> Stahl, „Offensive Jihad“.</p>
        <p><a href="#_ednref17" id="_edn17">[17]</a> McGregor, „Al-Qaeda’s Egyptian Prophet“.</p>
        <p><a href="#_ednref18" id="_edn18">[18]</a> Dale Eikmeier, „Qutbism: An Ideology of Islamic-Fascism“, Parameters, sv. 37, číslo 1, <a href="http://www.dtic.mil/docs/citations/ADA485995.">http://www.dtic.mil/docs/citations/ADA485995.</a></p>
        <p><a href="#_ednref19" id="_edn19">[19]</a> William McCants, „Problems With the Arabic Name Game“, Combating Terrorism Center, 22. mája 2006.</p>
        <p><a href="#_ednref20" id="_edn20">[20]</a> Hassan Hassan, <em>The Sectarianism of the Islamic State: Ideological Roots and Political Context</em> (Washington DC: Carnegie Endowment for International Peace, jún 2016), 26, <a href="https://carnegieendowment.org/files">https://carnegieendowment.org/files</a> / CP_253_Hassan_Islamic_State.pdf.</p>
        <p><a href="#_ednref21" id="_edn21">[21]</a> Roxanne L. Euben, „Mapping Modernities, ,Islamic‘ and ,Western‘“, in: <em>Border Crossings: Toward a Comparative Political Theory, </em>ed. Fred Dallmayr (Lanham, MD: Lexington Books, 1999), 20.</p>
        <p><a href="#_ednref22" id="_edn22">[22]</a> Vladimir Lenin, „What Is to Be Done?“ In: <em>Lenin</em>’<em>s Selected Works</em>, prekl. Joe Fineberg a George Hanna (Moscow: Foreign Languages ​​Publishing House, 1961), sv. 1, 119–271, Marxists Internet Archive, stránka navštívená 5. mája 2020, <a href="https://www.marxists.org/archive/lenin/works">https://www.marxists.org/archive/lenin/works</a> / 1901 / witbd.</p>
        <p><a href="#_ednref23" id="_edn23">[23]</a> Glenn E. Robinson, „Jihadi Information Strategy: Sources, Opportunities, and Vulnerabilities“, in: John Arquilla a Douglas A. Borer, eds., <em>Information Strategy and Warfare</em>: <em>A Guide to Theory and Practice</em> (London: Routledge, 2007), 92.</p>
        <p><a href="#_ednref24" id="_edn24">[24]</a> McGregor, „Al-Qaeda’s Egyptian Prophet“.</p>
        <p><a href="#_ednref25" id="_edn25">[25]</a> Abdallah al-Qutbi, citácie podľa: „Impaling Leninist Qutbi Doubts: Shaykh Ibn Jibreen Makes Takfir Upon (Declares as Kufr) the Saying of Sayyid Qutb That Islam Is a Mixture of Communism and Christianity“, TheMadKhalis.com, 2. januára 2010, <a href="http://www.themadkhalis.com/md/articles/bguiq-shaykh-ibn-jibreen-making-takfir-upon-the-saying-of-sayyid-qutb-that-islam-is-a-mixture-of-communism-and-christianity.cfm.">http://www.themadkhalis.com/md/articles/bguiq-shaykh-ibn-jibreen-making-takfir-upon-the-saying-of-sayyid-qutb-that-islam-is-a-mixture-of-communism-and-christianity.cfm.</a></p>
        <p><a href="#_ednref26" id="_edn26">[26]</a> Damon Linker, „The Marxist Roots of Islamic Extremism“, <em>The Week</em>, 25. marca 2016, <a href="http://theweek.com/articles/614207/marxist-roots-islamic-extremism.">http://theweek.com/articles/614207/marxist-roots-islamic-extremism.</a></p>
        <p><a href="#_ednref27" id="_edn27">[27]</a> Charles Moscowitz, Islamo-Communism: <em>The Communist Connection to Islamic Terrorism</em> (Boston: City Metro Enterprises, 2013).</p>
        <p><a href="#_ednref28" id="_edn28">[28]</a> Antero Leitzinger, „The Roots of Islamic Terrorism“, <em>The Eurasian Politician</em>, č. 5 (marec 2002), <a href="http://users.jyu.fi/~aphamala/pe/issue5/roots.htm.">http://users.jyu.fi/~aphamala/pe/issue5/roots.htm.</a></p>
        <p><a href="#_ednref29" id="_edn29">[29]</a> Lawrence Wright, <em>The Looming Tower</em>: Al-Qaeda and the Road to 9/11 (New York: Knopf Publishing Group, 2006), 42.</p>
        <p><a href="#_ednref30" id="_edn30">[30]</a> Dawn Perlmutter, <em>Investigating Religious Terrorism and Ritualistic Crimes</em> (New York: CRC Press, 2003), 104.</p>
        <p><a href="#_ednref31" id="_edn31">[31]</a> National Commission on Terrorist Attacks Upon the United States, <em>The 9/11 Commission Report </em>(Washington DC: National Commission on Terrorist Attacks Upon the United States, 2004), 55, https: //www.9-11commission.gov/report/911Report.pdf.</p>
        <p><a href="#_ednref32" id="_edn32">[32]</a> Wright, <em>The Looming Tower</em>, 36–37.</p>
        <p><a href="#_ednref33" id="_edn33">[33]</a> Lawrence Wright, „The Man Behind Bin Laden: How an Egyptian Doctor Became a Master of Terror“, <em>New Yorker</em>, 16. septembra 2002,</p>
        <p>https: //www.newyorker.com/magazine/2002/09/16/the-mn-behind-bin-laden.</p>
        <p><a href="#_ednref34" id="_edn34">[34]</a> Glenn E. Robinson, „The Four Waves of Global Jihad, 1979– 2017“, <em>Middle East Policy</em> 24, č. 3 (Jeseň 2017): 70, získané cez Research Gate 5. mája 2020,</p>
        <p><a href="https://www.researchgate.net/publication/319160351_The_Four_Waves_of_Global_Jihad_1979">https://www.researchgate.net/publication/319160351_The_Four_Waves_of_Global_Jihad_1979</a>–2017.</p>
        <p><a href="#_ednref35" id="_edn35">[35]</a> Robinson, „Jihadi Information Strategy“, 88.</p>
        <p><a href="#_ednref36" id="_edn36">[36]</a> Robinson, „The Four Waves of Global Jihad“, 85.</p>
        <p><a href="#_ednref37" id="_edn37">[37]</a> Anthony Bubalo a Greg Fealy, „Between the Global and the Local: Islamism, the Middle East, and Indonesia“, The Brookings Project on US Policy Towards the Islamic World, č. 9 (október 2005): 7, https: // www.brookings.edu/wp-content/uploads/2016/06/20051101bubalo_fealy.pdf.</p>
        <p><a href="#_ednref38" id="_edn38">[38]</a> Seth G. Jones, <em>A Persistent Threat: The Evolution of al Qa’ida and Other Salafi Jihadists</em> (Santa Monica, CA: RAND Corporation, 2014), 64–65, <a href="https://www.rand.org/content/dam/rand/pubs/research_reports/RR600/RR637/RAND_RR637.pdf.">https://www.rand.org/content/dam/rand/pubs/research_reports/RR600/RR637/RAND_RR637.pdf.</a></p>
        <p><a href="#_ednref39" id="_edn39">[39]</a> Robert Manne, „Sayyid Qutb: Father of Salafi Jihadism, Forerunner of the Islamic State“, <em>Australian Broadcasting Corporation</em>, 7. november 2016, <a href="http://www.abc.net.au/religion/articles/2016/11/07/4570251.htm.">http://www.abc.net.au/religion/articles/2016/11/07/4570251.htm.</a></p>
        <p><a href="#_ednref40" id="_edn40">[40]</a> Anthony Cordesman, „Islam and the Patterns in Terrorism and Violent Extremism“, <em>Center for Strategic and International Studies, </em>17. októbra 2017, <a href="https://www.csis.org/analysis/islam-and-patterns-terrorism-and-violent-extremism.">https://www.csis.org/analysis/islam-and-patterns-terrorism-and-violent-extremism.</a></p>
        <p><a href="#_ednref41" id="_edn41">[41]</a> Bureau of Counterterrorism and Countering Violent Extremism<em>, Country Reports on Terrorism 2018</em> (Washington, DC: Department of State, 2019), <a href="https://www.state.gov/reports/country-reports-on-terrorism-2018.">https://www.state.gov/reports/country-reports-on-terrorism-2018.</a></p>
        <p><a href="#_ednref42" id="_edn42">[42]</a> Alex Nowrasteh, „Terrorists by Immigration Status and Nationality: A Risk Analysis, 1975–2017“, <em>Cato Institute,</em> 7. mája 2019, https: // www.cato.org/publications/policy-analysis/terrorists-immigration-status-nationality-risk-analysis-1975-2017.</p>
        <p><a href="#_ednref43" id="_edn43">[43]</a> Shi Yanchun 時延春, „Zhou Enlai yu Zhongdong“ 周恩來與中東 [ „Čou En-laj a&nbsp;Stredný východ“], <em>Party History in Review,</em> číslo 1 (2006), 7–8, http: //waas.cssn.cn / webpic / web / waas / upload / 2011/06 / d20110602193952375.pdf. [v čínštine].</p>
        <p><a href="#_ednref44" id="_edn44">[44]</a> Stefan M. Aubrey, <em>The New Dimension of International Terrorism </em>(Zürich: vdf Hochschulverlag AG an der ETH, 2004), 34–36.</p>
        <p><a href="#_ednref45" id="_edn45">[45]</a> „911 kongbufenzi xiji shijian zhi hou: guonei yanlun zhaideng“&nbsp; 911恐怖分子袭击事件之后：国内言论摘登 [„A Sampling of Chinese Public Opinion Following the 9/11 Terrorist Attacks“], <em>Modern China Studies</em>, číslo 4 (2001), http: //www.modernchinastudies.org/us/issues/past-issues/75-mcs-2001-issue-4 / 596-911.html. [v čínštine].</p>
        <p><a href="#_ednref46" id="_edn46">[46]</a> Yitzhak Shichor, „The Great Wall of Steel: Military and Strategy“, in: S. Frederick Starr, ed., <em>Xinjiang: China’s Muslim</em> <em>Borderland </em>(London: Routledge, 2004), 149.</p>
        <p><a href="#_ednref47" id="_edn47">[47]</a> John Hooper, „Claims That China Paid Bin Laden to See Cruise Missiles“, <em>The Guardian,</em> 19. októbra 2001, <a href="https://www.theguardian.com/world">https://www.theguardian.com/world</a> /2001/w/20/china.afghanistan.</p>
        <p><a href="#_ednref48" id="_edn48">[48]</a> „Chinese Firms Helping Put Phone System in Kabul“, <em>The Washington Times</em>, 28. septembra 2001, <a href="https://www.washingtontimes.com/news/2001/sep">https://www.washingtontimes.com/news/2001/sep</a> / 28 / 20010928-025638-7645r.</p>
        <p><a href="#_ednref49" id="_edn49">[49]</a> Shichor, „The Great Wall of Steel“, 158.</p>
        <p><a href="#_ednref50" id="_edn50">[50]</a> Qiao Liang 乔良 a Wang Xiangsui 王湘穗, <em>Chao xian zhan </em>超限战<em> [Neobmedzená vojna],</em> (Beijing: Zhongguo shehui chubanshe, 2005), kap. 2. [v čínštine].</p>
        <p><a href="#_ednref51" id="_edn51">[51]</a> D. J. McGuire, „How Communist China Supports Anti-US Terrorists“, <em>Association for Asian Research</em>, 15. septembra 2005,</p>
        <p><a href="https://web.archive.org/web/20110914053923/http">https://web.archive.org/web/20110914053923/http</a>://www.asianresearch.org/articles / 2733.html.</p>
        <p><a href="#_ednref52" id="_edn52">[52]</a> Daniel Flynn, <em>Why the Left Hates America</em>: <em>Exposing the Lies That Have Obscured Our Nation’s Greatness</em> (United States: Crown Publishing Group, 2004).</p>
        <p><a href="#_ednref53" id="_edn53">[53]</a> „Ward Churchill“ [profil], Discover the Networks, stránka navštívená 5. mája 2020, <a href="http://www.discoverthenetworks.org/individualProfile.asp?">http://www.discoverthenetworks.org/individualProfile.asp?</a> indid = 1835.</p>
        <p><a href="#_ednref54" id="_edn54">[54]</a> Prepis nahrávky Usámu bin Ládina, BBC, 12. februára 2003, stránka navštívená 9. júna 2020, <a href="http://news.bbc.co.uk/2/hi/middle_east">http://news.bbc.co.uk/2/hi/middle_east</a> / 2751019.stm.</p>
        <p><a href="#_ednref55" id="_edn55">[55]</a> Jamie Glazov, <em>United in Hate: The Left’s Romance With Tyranny and</em> Terror (Los Angeles: WND Books, 2009), 164–165.</p>
        <p><a href="#_ednref56" id="_edn56">[56]</a> David Horowitz, <em>Unholy Alliance: Radical Islam and the American Left</em> (Washington DC: Regnery Publishing, Inc., 2004), 37.</p>
        <p><a href="#_ednref57" id="_edn57">[57]</a> Glazov, <em>United in Hate,</em> 159–176.</p>
        <p><a href="#_ednref58" id="_edn58">[58]</a> „Nicholas De Genova“ [profil], Discover the Networks, stránka navštívená 5. mája 2020, <a href="http://www.discoverthenetworks.org/individualProfile.asp?indid">http://www.discoverthenetworks.org/individualProfile.asp?indid</a> =2189.</p>
        <p><a href="#_ednref59" id="_edn59">[59]</a> „Lynne Stewart“ [profil], Discover the Networks, stránka navštívená 5. mája 2020, <a href="http://www.discoverthenetworks.org/individualProfile.asp?">http://www.discoverthenetworks.org/individualProfile.asp?</a> indid = 861.</p>
      <Link to={`/kapitola_16`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


